import { NbDateService } from '@nebular/theme';
import { Injectable } from '@angular/core';
import { Presence } from '@api-services/models/presence';
import { PresenceService as PresenceApiService } from '@api-services/services/presence/presence.service';
import { DateUtilityService } from '@services/date-utility/date-utility.service';
import { FilterService } from '@services/filter/filter.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, combineLatest } from 'rxjs';
import * as moment from 'moment';
import { ScheduleEnum } from '@api-services/enums/schedule-enum';
import { PresenceEnum } from '@api-services/enums/presence-enum';

@Injectable({
  providedIn: 'root'
})
export class PresenceService {

  $data: BehaviorSubject<Presence[]> = new BehaviorSubject<Presence[]>([])
  $totalData: BehaviorSubject<Presence[]> = new BehaviorSubject<Presence[]>([])
  private data: Presence[] = []
  private filterData: Presence[] = []
  total = 0
  rowsPerPageSelected = 50;
  page = 0
  pages = 0
  search = '';
  states = [];
  infoPagination = ''
  public notFoundSearch: boolean = undefined;
  hasNightSchedule = false;

  constructor(private filterService: FilterService,
    private presenceApiService: PresenceApiService,
    private dateService: NbDateService<Date>,
    private dateUtilityService: DateUtilityService,
    private spinnerService: NgxSpinnerService) {
   
    combineLatest([filterService.$search, filterService.$states]).subscribe(([search, states]) => {
      this.search = search
      this.states = states

      this.page = 0

      this.find()
    });

  }
    

  list(date: Date, agent = false) {
    this.spinnerService.show("loader")
    this.data = [];
    this.$totalData.next([])
    this.$data.next([])
    
    
    const startOfMonth = 
     new Date( moment(this.dateService.addDay(this.dateService.addMonth(new Date(), -2) , -1)).startOf('month').format('YYYY-MM-DD'))
    const to = new Date().getDate() == 1 ? new Date(moment().format('YYYY-MM-DD')) : new Date(moment().subtract(1, 'days').format('YYYY-MM-DD'))
    let queryAgent
    agent ? 
     queryAgent = {
      page: 0, rowsPage: 200, currentDate: date.toISOString(), From: startOfMonth.toISOString(), To: to.toISOString()
    }:
    queryAgent = {
      page: 0, rowsPage: 200, currentDate: date.toISOString() 
    }

    this.presenceApiService.list(queryAgent).then((values) => {
    const formatTimeZone = this.formatData(values);  
    this.calculateDataHours(formatTimeZone, date)     
    }).finally(() => {
      this.spinnerService.hide("loader")
    });
    
     
  
  }

  listHeaderPresence(date: Date, agent = false){
    this.spinnerService.show("loader")
    this.data = [];
    this.$totalData.next([])
    this.$data.next([])
    
    
    const startOfMonth = 
     new Date( moment(this.dateService.addDay(this.dateService.addMonth(new Date(), -2) , -1)).startOf('month').format('YYYY-MM-DD'))
    const to = new Date().getDate() == 1 ? new Date(moment().format('YYYY-MM-DD')) : new Date(moment().subtract(1, 'days').format('YYYY-MM-DD'))
    let queryAgent
    agent ? 
     queryAgent = {
      page: 0, rowsPage: 200, currentDate: date.toISOString(), From: startOfMonth.toISOString(), To: to.toISOString()
    }:
    queryAgent = {
      page: 0, rowsPage: 200, currentDate: date.toISOString() 
    }

    this.presenceApiService.listPresencesHeader(queryAgent).then((values) => {
    const formatTimeZone = this.formatData(values);  

    this.data = formatTimeZone.sort((a:Presence, b:Presence) => a.agentName.localeCompare(b.agentName))

    this.$totalData.next(formatTimeZone)

    this.find()
    //this.calculateDataHours(formatTimeZone, date)     
    }).finally(() => {
      this.spinnerService.hide("loader")
    });
  }

  async getByLegajo(legajo:string, account:string , puesto:string, date:Date){
    
    this.spinnerService.show("loader")
    this.data = [];
    this.$totalData.next([])
    this.$data.next([])

    const filter = {page: 0, rowsPage: 200, currentDate: date.toISOString()}

    this.presenceApiService.getPresenceByLegajo(legajo, account , puesto , filter).then((values) => {

      const formatTimeZone = this.formatData(values);  

    this.data = formatTimeZone.sort((a:Presence, b:Presence) => a.agentName.localeCompare(b.agentName))

    this.$totalData.next(formatTimeZone)
  
      this.find()  
      }).finally(() => {
        this.spinnerService.hide("loader")
      });
    
  }


  calculateDataHours(values, date){

  
    
    const currentDate = this.dateService.isSameDaySafe(date, new Date())
    const filterCurrentSchedule = currentDate ? values.filter(present=>present.currentSchedule)
    : values
    this.data = filterCurrentSchedule.sort((a:Presence, b:Presence) => a.agentName.localeCompare(b.agentName))

    


    this.$totalData.next(filterCurrentSchedule)

    this.find()

  }

  removeRoundedIncidents(pres: Presence) {
     // Parche hasta ver que hacer con los sucesos menores a 30s en el backend
    if(pres.earlyArrival < 0.30) {
      pres.incidents = pres.incidents?.filter(i=>{
        return i !== "EarlyArrive"
      })
    } 
    if(pres.earlyRetreat < 0.30) {
      pres.incidents = pres.incidents?.filter(i=>{
        return i !== "LeftEarly"
      })
    } 
    if(pres.lateArrival < 0.30) {
      pres.incidents = pres.incidents?.filter(i=>{
        return i !== "LateArrive"
      })
    } 
    if(pres.lateRetreat < 0.30) {
      pres.incidents = pres.incidents?.filter(i=>{
        return i !== "LeftLate"
      })
    } 


    return pres
  }

  

  donwloadCsv(date: Date){
    const labelDate = date.toISOString().split('T')[0]
    
    const  queryAgent = {
      page: 0, rowsPage: 200, date: date.toISOString() 
    }
    this.presenceApiService.csv(queryAgent).subscribe(data => {

      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      const accountName = JSON.parse(localStorage.getItem('employee')).cuenta
      const legajo = JSON.parse(localStorage.getItem('employee')).legajo
      a.download = `${accountName}_${legajo}_${labelDate}.csv`;
      a.click();

      // Limpia el objeto URL
      window.URL.revokeObjectURL(url);
    });
  }

  formatData(values:any){
    values = values.filter((v)=>{
      return !v.logicDelete 
    });
    // values = values.filter((v)=>{
    //   let allInactive = false;
    //   if(v.presences) {        
    //     allInactive = v.presences.length>0 && v.presences.every((p)=>{    //comentado porque parece que no hace falta
    //       return p && !p.active
    //     });  
    //   }
    //   return !allInactive
    // });
    values.map(v=>{        
      if(v.presences) {
        v.presences.forEach((presence)=>{
          presence.entry = presence.entry ?  this.dateUtilityService.convertToLocal(this.roundDate(presence.entry)) : null
          presence.exit = presence.exit ? this.dateUtilityService.convertToLocal(this.roundDate(presence.exit)) : null
          presence.login = presence.login ?  this.dateUtilityService.convertToLocal(this.roundDate(presence.login)) : null
          presence.logout = presence.logout ?  this.dateUtilityService.convertToLocal(this.roundDate(presence.logout)) : null

          presence.totalHours = presence.totalHours ? this.dateUtilityService.redondeoMinutosToParam(presence.totalHours, 30) : null
          presence.expectedHours = presence.expectedHours ? this.dateUtilityService.redondeoMinutosToParam(presence.expectedHours, 30) : null
          presence.presentHours = presence.presentHours ? this.dateUtilityService.redondeoMinutosToParam(presence.presentHours, 30) : null
          presence.lateArrival = presence.lateArrival ? this.dateUtilityService.redondeoMinutosToParam(presence.lateArrival, 30) : null
          presence.earlyArrival = presence.earlyArrival ? this.dateUtilityService.redondeoMinutosToParam(presence.earlyArrival, 30) : null
          presence.lateRetreat = presence.lateRetreat ? this.dateUtilityService.redondeoMinutosToParam(presence.lateRetreat, 30) : null
          presence.earlyRetreat = presence.earlyRetreat ? this.dateUtilityService.redondeoMinutosToParam(presence.earlyRetreat, 30) : null
          presence.inasistenceInHours = presence.inasistenceInHours ? this.dateUtilityService.redondeoMinutosToParam(presence.inasistenceInHours, 30) : null
          presence.deviation = presence.deviation ? this.dateUtilityService.redondeoMinutosToParam(presence.deviation, 30) : null
        })
      }
      if(!v.active) {
        v.status = PresenceEnum.Void;
        v.totalHours = null;
      }
      // TODO: ordenar los fields para que sea mas legible, por tipo de presencialidad y logica
     v.entry = v.entry ?  this.dateUtilityService.convertToLocal(this.roundDate(v.entry)) : null
     v.exit = v.exit ? this.dateUtilityService.convertToLocal(this.roundDate(v.exit)) : null
     v.login = v.login ?  this.dateUtilityService.convertToLocal(this.roundDate(v.login)) : null
     v.logout = v.logout ?  this.dateUtilityService.convertToLocal(this.roundDate(v.logout)) : null
     
     
     v.totalHours = v.totalHours ? this.dateUtilityService.redondeoMinutosToParam(v.totalHours, 30) : null
     v.expectedHours = v.expectedHours ? this.dateUtilityService.redondeoMinutosToParam(v.expectedHours, 30) : null
     v.presentHours = v.presentHours ? this.dateUtilityService.redondeoMinutosToParam(v.presentHours, 30) : null
     v.lateArrival = v.lateArrival ? this.dateUtilityService.redondeoMinutosToParam(v.lateArrival, 30) : null
     v.earlyArrival = v.earlyArrival ? this.dateUtilityService.redondeoMinutosToParam(v.earlyArrival, 30) : null
     v.lateRetreat = v.lateRetreat ? this.dateUtilityService.redondeoMinutosToParam(v.lateRetreat, 30) : null
     v.earlyRetreat = v.earlyRetreat ? this.dateUtilityService.redondeoMinutosToParam(v.earlyRetreat, 30) : null
     v.inasistenceInHours = v.inasistenceInHours ? this.dateUtilityService.redondeoMinutosToParam(v.inasistenceInHours, 30) : null
     v.deviation = v.deviation ? this.dateUtilityService.redondeoMinutosToParam(v.deviation, 30) : null


    })
  
    return values
  }

  roundDate(inputDate: Date): Date {
    // Crear una copia de la fecha para evitar modificar la original
    const roundedDate = new Date(inputDate);

    // Obtener los segundos de la fecha
    const seconds = roundedDate.getSeconds();

    // Redondear hacia arriba si los segundos son mayores a 30
    if (seconds > 30) {
      roundedDate.setMinutes(roundedDate.getMinutes() + 1); // Aumentar un minuto
      roundedDate.setSeconds(0); // Establecer los segundos en 0
    }
    // Redondear hacia abajo si los segundos son menores o iguales a 30
    else {
      roundedDate.setSeconds(0); // Establecer los segundos en 0
    }

    return roundedDate;
  }


  nextPage() {
    if (this.page < this.pages - 1) {
      this.page += 1
      this.find()
    }

  }

  prevPage() {
    if (this.page > 0) {
      this.page -= 1
      this.find()
    }
  }

  changeRowsPerPage() {
    this.page = 0
    this.find()
  }

  find() {
    const currentData = this.data;
    let dataFilter : Presence[] = [];

    const presents = [
        'Presence',
        'InasistenceWithOutNotice',
        'NoSchedule',
        'WithNotice',
        'License',
        'NonAttendance'
    ]

    const incidents = ['EarlyArrive', 'LateArrive','LeftEarly', 'LeftLate',]
    
    const presentsFilter = this.states.filter(data=>  presents?.some(
      (p)=>{
        return p.toString() === data.toString()
      }
    )).sort()
    const incidentsFilter = this.states.filter(data=> incidents?.some(
      (p)=>{
        return p.toString() === data.toString()
      }
    )).sort()
    const groupersFilter = this.states.filter(id=>typeof(id) === 'number').sort()

   dataFilter =  currentData.filter((item:any) => {
      const nombreCoincide = (this.search.length == 0 || item.agentName.toUpperCase().includes(this.search.toUpperCase())) 
      const legajoCoincide = item.agentNumber.includes(this.search)    
      const groupersCoincide = (groupersFilter.length == 0 || this.states.find(data=> data == item.splitId.toString())) 
      
      if(!item.presences) {
        const presentsCoincide = (presentsFilter.length == 0 || presentsFilter.find(data=> (data == item.status?.toString())))     
        const incidensCoincide = incidentsFilter.length == 0 ||  incidentsFilter.some(objeto2 => item.incidents?.some(incident=>incident.toString() == objeto2.toString()))
              
      return  (nombreCoincide || legajoCoincide) && (presentsCoincide && incidensCoincide) && (groupersCoincide)
      } else { 
        // para multipresencialidad recorro cada una

        const presentsCoincide = (presentsFilter.length == 0 || presentsFilter.find((data)=>{ 
         return item.presences.some(
            (p)=>{
              return p.status.toString() === data.toString()
            }
          )
        }));
        const incidensCoincide = incidentsFilter.length == 0 ||  incidentsFilter.find((objeto2) => {
          return item.presences.some(
            (p)=> {
              return p.incidents?.some((incident)=>{
                return incident.toString() == objeto2.toString()
              })}
            )
        })              
        return  (nombreCoincide || legajoCoincide) && (presentsCoincide && incidensCoincide) && (groupersCoincide)
      }     

    })

    const filteredLicenseStatusArray = dataFilter.filter((item, index, self) => 
      
      item.status !== 'License' || 
      index === self.findIndex(t => t.agentNumber === item.agentNumber && t.status === 'License')
    ); 
    const dataFilterPagination = [...filteredLicenseStatusArray].slice(this.page * this.rowsPerPageSelected, (this.page * this.rowsPerPageSelected) + this.rowsPerPageSelected);
    

    this.hasNightSchedule = dataFilterPagination?.some((h)=>{
      return h.presences?.some((p)=>{
        return moment(p.entry).day() !== moment(p.exit).day() && p.entry
      })
    });
    
    console.log(this.hasNightSchedule);
    this.$data.next(dataFilterPagination);
    

    const countData = this.search.length === 0 && presentsFilter.length == 0
     && incidentsFilter.length == 0 ? currentData.length : dataFilter.length

    this.pages = Math.ceil(countData / this.rowsPerPageSelected)
    this.total = countData

      this.notFoundSearch = this.$data.value.length === 0;
      const currentRow = (this.page * this.rowsPerPageSelected) + 1
      const lastRow = (this.page * this.rowsPerPageSelected) + this.rowsPerPageSelected
      this.infoPagination = `${currentRow} - ${lastRow > this.total ? this.total : lastRow} de ${this.total}`
      
    

  }



  clear(){
    this.data = [];
    this.$totalData.next([])
    this.$data.next([])
  }

  mockPrecense(): Presence[] {

    return []
  }
}
