import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbIconLibraries,
  NbTooltipModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
} from './layouts';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbTooltipModule,
  NbEvaIconsModule,
  RouterModule,
  AngularSvgIconModule
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {

  constructor(iconsLibrary: NbIconLibraries) {
    iconsLibrary.registerSvgPack('apex', {
      'ar': '<img src="../../assets/icons/ar.svg" width="12px">',
      'br': '<img src="../../assets/icons/br.svg" width="12px">',
      'top': '<img src="../../assets/icons/Miscelanea.svg" width="154" height="100px">',
      'withdrawall': '<img src="../../assets/icons/withdrawall.svg" width="16px" height="16px">',
      'withdrawall-before': '<img src="../../assets/icons/withdrawall-before.svg" width="16px" height="16px">',
      'arrive-after': '<img src="../../assets/icons/arrive-after.svg" width="16px" height="16px">',
      'arrive-before': '<img src="../../assets/icons/arrive-before.svg" width="16px" height="16px">',
      'arrow-left': '<img src="../../assets/icons/arrow-left.svg" width="24px" height="24px">',
      'arrow-right': '<img src="../../assets/icons/arrow-right.svg" width="24px" height="24px">',
      'chip-ok': '<img src="../../assets/icons/chip-ok.svg" width="18px" height="18px">',
      'chip-warning': '<img src="../../assets/icons/chip-warning.svg" width="18px" height="18px">',
      'chip-license': '<img src="../../assets/icons/chip-license.svg" width="18px" height="18px">',
      'zoom-in': '<img src="../../assets/icons/zoom-in.svg" width="18px" height="18px">',
      'zoom-out': '<img src="../../assets/icons/zoom-out.svg" width="18px" height="18px">',
      'clock': `<img src="../../assets/icons/clock.svg" width="24px" height="24px">`,
      'clock-red': `<img src="../../assets/icons/clock-red.svg" width="24px" height="24px">`,
      'accent-ok': '<img src="../../assets/icons/accent-ok.svg" width="24px" height="24px">',
      'not-accomplished': '<img src="../../assets/icons/not-accomplished.svg" width="24px" height="24px">',
      'warning': '<img src="../../assets/icons/warning.svg" width="24px" height="24px">',
      'warning-blue': '<img src="../../assets/icons/warning-blue.svg" width="24px" height="24px">',
      'attention': '<img src="../../assets/icons/attention.svg" width="24px" height="24px">',
      'early-arraive': '<img src="../../assets/icons/early-arraive.svg" width="24px" height="24px">',
      'calendar-green': '<img src="../../assets/icons/calendar-green.svg" width="32px" height="32px">',
      'license': '<img src="../../assets/icons/license.svg" width="22px" height="22px">',
      'free-day': '<img src="../../assets/icons/free-day.svg" width="22px" height="22px">',
      'download-csv': '<img src="../../assets/icons/arrow-down-csv.svg" width="22px" height="22px">',
      'download-csv-disabled': '<img src="../../assets/icons/arrow-down-csv-disabled.svg" width="22px" height="22px">',
      'adjunto': '<img src="../../assets/icons/adjunto.svg" width="16px" height="16px">',
      'adjunto-white': '<img src="../../assets/icons/adjunto-white.svg" width="16px" height="16px">',
      'adjunto-32': '<img src="../../assets/icons/adjunto.svg" width="32px" height="32px">',
      'open-state': '<img src="../../assets/icons/open-state.svg" width="16px" height="16px">',
      'check': '<img src="../../assets/icons/check.svg" width="16px" height="16px">',
      'check-black': '<img src="../../assets/icons/check-black.svg" width="24px" height="24px">',
      'check-xl': '<img src="../../assets/icons/check.svg" width="32px" height="32px">',
      'check-circle': '<img src="../../assets/icons/check-circle.svg" width="16px" height="16px">',
      'file': '<img src="../../assets/icons/file.svg" width="16px" height="16px">',
      'eye': '<img src="../../assets/icons/eye.svg" width="16px" height="16px">',
      'email': '<img src="../../assets/icons/email.svg" width="16px" height="16px">',
      'lock': '<img src="../../assets/icons/lock.svg" width="24px" height="24px">',
      'edit': '<img src="../../assets/icons/edit-gray.svg" width="24px" height="24px">',
      'register-deslogueo': '<img src="../../assets/icons/register-deslogueo.svg" width="32px" height="32px">',
      'delete-adjunto': '<img src="../../assets/icons/delete-adjunto.svg" width="16px" height="16px">',
      'filter-clean': '<img src="../../assets/icons/filterclean.svg" width="16px" height="16px">',

    });
    
  }
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'Apex',
          },
          [],
        ).providers as []
      ],
    };
  }
}
