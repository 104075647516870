import { Injectable } from '@angular/core';
import { format, differenceInMinutes } from 'date-fns';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateUtilityService {
    calculateTimeDifferenceInMinutes(startMinutes: number, endMinutes: number): string {

     

        const diffInMinutes = endMinutes - startMinutes;
        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        //return diffInMinutes
        
        return format(new Date(0, 0, 0, hours, minutes), 'HH:mm');
      }


      getMinutesFromTimeString(timeString: string): number {
        if (!timeString) {
            return 0
        }
        const parts = timeString.split("T")[1].split(":");
        const hours = parseInt(parts[0]);
        const minutes = parseInt(parts[1]);
        return hours * 60 + minutes;
      }


      calculateTimeDifference(start: Date, end: Date): number {
        const diffInMinutes = differenceInMinutes(new Date(end), new Date(start));
        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;
        return diffInMinutes
       // return format(new Date(0, 0, 0, hours, minutes), 'HH:mm');
      }

      convertToLocal(date){
        return date // TODO: Remove all calls to the funciton
      }

       redondeoMinutosToParam(valor, redondeo) {

        // Dividir el valor en minutos y segundos
        const minutos = Math.floor(valor);
        const segundos = Math.round((valor - minutos) * 60);
       
         if (valor == 0) return 0
        const minutesR = segundos > redondeo ? minutos + 1  : minutos

        return minutesR
        /*  try {
           return moment().hour(0).minutes(segundos > redondeo ? minutos + 1  : minutos).format('H[h] m[m]')
         } catch (error) {
           return '';
         } */
       }

       roundDate(inputDate: Date): Date {
        // Crear una copia de la fecha para evitar modificar la original
       
        const roundedDate = new Date(inputDate);
        
        // Obtener los segundos de la fecha
        const seconds = roundedDate.getSeconds();
        
    
        // Redondear hacia arriba si los segundos son mayores a 30
        if (seconds > 30) {
          roundedDate.setMinutes(roundedDate.getMinutes() + 1); // Aumentar un minuto
          roundedDate.setSeconds(0); // Establecer los segundos en 0
        }
        // Redondear hacia abajo si los segundos son menores o iguales a 30
        else {
          roundedDate.setSeconds(0); // Establecer los segundos en 0
        }
    
        return roundedDate;
      }


      minutesToHour2(value: number): number {
        
    
        if (value == null) {
            return 0;
        }
        
        // Separar parte entera y decimal
        const [parteEntera, parteDecimalStr] = value.toString().split('.');
        const parteDecimal = parteDecimalStr ? parseFloat('0.' + parteDecimalStr) : 0;
        // Verificar si la parte decimal es mayor o igual a 0.5 (30 segundos)
        const redondeo = parteDecimal >= 0.5 ? +parteEntera + 1 : +parteEntera;
    
        
        return redondeo;
    }

    milisecondsToHour(value) { // esta recibe MS
      const duration = moment.duration(value);
  
      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.asMinutes()) % 60;
  
     
  
      return `${hours}H ${minutes}M`;
    }

    minutesToHour(value) {
      try {
        return moment().hour(0).minutes(value).format('H:m') == '0:0'
          ? '00:00'
          : moment().hour(0).minutes(value).format('HH:mm');
      } catch (error) {
        return '00:00';
      }
    }
    
    
}
