import { Injectable } from '@angular/core';
import { NbDateService } from '@nebular/theme';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor(private dateService: NbDateService<Date>){}

  $search: BehaviorSubject<string> = new BehaviorSubject<string>('')
  $date: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date())
  $states: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([])
  $zoom: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  $arrowMove: BehaviorSubject<string> = new BehaviorSubject<string>('')
  $dateAgent: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date())
  $deslogueoState: BehaviorSubject<string> = new BehaviorSubject<string>('')
  $deslogueoType: BehaviorSubject<string> = new BehaviorSubject<string>('')
  $deslogueoMonth: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  $isToday: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  $deslogueoMotive: BehaviorSubject<string> = new BehaviorSubject<string>('')
  $gap : BehaviorSubject<number> = new BehaviorSubject<number>(11)
  lastRoute = localStorage.getItem('lastRoute') ? localStorage.getItem('lastRoute') : 'dashboard'
  $routeCache: BehaviorSubject<string> = new BehaviorSubject<string>(this.lastRoute)
  

  onChangeSearch(value: string) {
    this.$search.next(value)
  }
  onGap(value: number) {
    this.$gap.next(value)
  }
  onChangeRouteCache(value: string) {
    this.$routeCache.next(value)
  }

  onChangeDate(date: Date) {
    
    this.$isToday.next(this.dateService.isSameDaySafe(new Date() , new Date(date)))
    this.$date.next(date)
  }
  onChangeState(states: any[]) {
    this.$states.next(states)
  }

  onChangeDeslogueoState(state:string) {
   
    this.$deslogueoState.next(state)
  }
  onChangeDeslogueoType(type:string) {
   
    this.$deslogueoType.next(type)
  }
  onChangeDeslogueoMonth(month:boolean) {
   
    this.$deslogueoMonth.next(month)
  }
  onChangeDeslogueoMotive(motive:string) {
   
    this.$deslogueoMotive.next(motive)
  }
 
  onChangeZoomState(zoom: boolean) {
   
    this.$zoom.next(zoom)
  }
  arrowMoved(move: string) {
    this.$arrowMove.next(move)
  }
}
